<template>
  <div
    :id="`job-expected-cost-lines-${linesData.id}`"
    class="job-expected-cost-lines-table-container"
  >
    <b-row
      align-h="between"
      align-v="center"
      class="p-2 cursor-pointer"
      @click="collapse = !collapse"
    >
      <b-col class="text-gray-50 font-weight-bold font-small" cols="auto">
        <span class="text-uppercase">{{ name }}</span>
      </b-col>
      <b-col cols="auto">
        <FeatherIcon
          v-if="!collapse"
          icon="ChevronDownIcon"
          class="text-primary"
        />
        <FeatherIcon v-else icon="ChevronUpIcon" class="text-primary" />
      </b-col>
    </b-row>
    <b-collapse v-model="collapse">
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <!-- fixed -->
        <b-table
          hover
          responsive
          :fields="fields"
          :items="lines"
          :no-local-sorting="true"
          @row-clicked="handleRowClick"
          @row-hovered="handleRowHovered($event)"
        >
          <template #cell(expected_cost_date)="data">
            <b-form-datepicker
              v-if="rowsEditionEnabled[data.item.id]"
              v-model="data.item.expected_cost_date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              placeholder=""
              size="sm"
              reset-button
              :hide-header="true"
              :label-help="null"
              boundary="viewport"
              start-weekday="1"
            />
            <span v-else>{{ data.value | formatDate }}</span>
          </template>
          <template #cell(description)="data">
            <b-form-input
              v-if="rowsEditionEnabled[data.item.id]"
              v-model="data.item.description"
              class="mb-1"
            />
            <span v-else>{{ data.value }}</span>
            <span v-if="rowsEditionEnabled[data.item.id]"> Categoria </span>
            <BaseSelect
              v-if="rowsEditionEnabled[data.item.id]"
              id="category-group"
              class="mb-1"
              :httpMethod="'get'"
              :key="categoryKey"
              :value="data.item.category_group"
              :resource="$data.$constants.RESOURCES.RESOURCE_CATEGORY_GROUPS"
              @input="
                (data.item.category_group = $event),
                  (categoryKey = `${new Date()}`)
              "
            />
            <span v-if="rowsEditionEnabled[data.item.id] && isMaintenance">
              Categoria de revisión
            </span>
            <v-select
              v-if="rowsEditionEnabled[data.item.id] && isMaintenance"
              id="revision-type"
              :value="data.item.revision_type"
              :options="[
                { name: 'Trimestral 1', value: 'T1' },
                { name: 'Trimestral 2', value: 'T2' },
                { name: 'Anual', value: 'A' },
                { name: 'Semestral', value: 'S' },
              ]"
              :reduce="(item) => item.value"
              label="name"
              @input="data.item.revision_type = $event"
            />
            <p
              v-else-if="
                isMaintenance &&
                !rowsEditionEnabled[data.item.id] &&
                data.item.revision_type
              "
            >
              Categoría revisión: {{ data.item.revision_name }}
            </p>
            <p v-if="data.item.total_hours">
              Horas imputadas: {{ data.item.total_hours }}
            </p>
          </template>
          <template #cell(initial_prevision)="data">
            <b-input-group
              v-if="rowsEditionEnabled[data.item.id] && (isSuperAdmin || job.start_document.document.length === 0 )"
              class="input-group-merge"
            >
              <b-form-input
                v-model="data.item.initial_prevision"
                type="number"
              />
              <b-input-group-append is-text> € </b-input-group-append>
            </b-input-group>
            <span v-else style="opacity: 0.6"
              >{{ data.value | numberToLocalString }} €</span
            >
          </template>
          <template #cell(prevision)="data">
            <b-input-group
              v-if="rowsEditionEnabled[data.item.id]"
              class="input-group-merge"
            >
              <b-form-input v-model="data.item.prevision" type="number" />
              <b-input-group-append is-text> € </b-input-group-append>
            </b-input-group>
            <span v-else>{{ data.value | numberToLocalString }} €</span>
          </template>
          <template #cell(deviation)="data">
            <span v-if="data.item.deviation > 0" class="text-danger">
              {{ data.item.deviation | numberToLocalString }} €
            </span>
            <span v-else-if="data.item.deviation < 0" class="text-success">
              {{ data.item.deviation | numberToLocalString }} €
            </span>
            <span v-else>{{ data.value | numberToLocalString }} €</span>
          </template>
          <template #cell(order)="data">
            <span>{{ data.value | numberToLocalString }} €</span>
          </template>
          <template #cell(pendent)="data">
            <span>{{ data.value | numberToLocalString }} €</span>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-end align-items-center">
              <template v-if="!readonly">
                <b-button
                  v-if=" data.item.pendent > 0  "
                  variant="primary"
                  class="text-nowrap rounded-sm p-1 mx-1"
                  @click="handleNewOrderButtonClick(data.item.id)"
                >
                  Nuevo pedido
                </b-button>
                <div class="container-actions">
                <b-link
                  v-if="!rowsEditionEnabled[data.item.id] && data.item.log"
                  class="d-inline-block text-indigo pr-1"
                >
                  <feather-icon
                    v-b-tooltip.hover
                    :title="data.item.log"
                    icon="InfoIcon"
                    size="18"
                  />
                </b-link>
                <b-link
                  v-if="!rowsEditionEnabled[data.item.id]"
                  class="text-indigo pr-1"
                  @click="
                    rowsEditionEnabled = { index: data.item.id, value: true }
                  "
                >
                  <feather-icon
                    v-b-tooltip.hover
                    title="Editar"
                    icon="Edit2Icon"
                    size="18"
                  />
                </b-link>
                <b-link
                  v-else
                  class="d-inline-block text-indigo pr-1"
                  @click="handleSaveIconClick(data)"
                >
                  <feather-icon icon="SaveIcon" size="18" />
                </b-link>
                <b-link
                  v-if="data.item.orders.length === 0"
                  class="d-inline-block text-danger pr-1"
                  @click="handleDeleteIconClick(data.item.id)"
                >
                  <feather-icon
                    v-b-tooltip.hover
                    title="Eliminar"
                    icon="TrashIcon"
                    size="18"
                  />
                </b-link>
                </div>
              </template>
              <div class="container-collapse">
              <span
                v-if="data.item.orders.length > 0"
                class="collapse-icon-container"
              >
                <b-link class="d-inline-block" @click="data.toggleDetails">
                  <FeatherIcon
                    v-if="!data.detailsShowing"
                    icon="ChevronDownIcon"
                    class="text-primary"
                  />
                  <FeatherIcon
                    v-else
                    icon="ChevronUpIcon"
                    class="text-primary"
                  />
                </b-link>
              </span>
              </div>
            </div>
          </template>
          <template #row-details="row">
            <JobExpectedCostLineOrdersTable
              :orders="row.item.orders"
              @uploadInfo="loadJobData"
            />
          </template>
          <template #custom-foot>
            <b-tr>
              <b-td />
              <b-td class="text-right text-uppercase font-weight-bold">
                Totales:
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                {{ totalInitialPrevision }}
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                {{ totalPrevision }}
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                <span
                  v-if="linesData.total_deviation > 0"
                  class="text-danger"
                  >{{ totalDeviation }}</span
                >
                <span
                  v-else-if="linesData.total_deviation < 0"
                  class="text-success"
                  >{{ totalDeviation }}</span
                >
                <span v-else>{{ totalDeviation }}</span>
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                {{ totalOrder }}
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                {{ totalPendent }}
              </b-td>
              <b-td />
            </b-tr>
          </template>
        </b-table>
      </b-overlay>
    </b-collapse>
    <JobExpectedCostLineOrderModal
      v-model="modalVisible"
      @create-order="handleCreateOrder"
    />
  </div>
</template>

<script>
import JobExpectedCostLinesApi from "@/api/job-expected-cost-lines-api";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import JobExpectedCostLineOrdersTable from "@/components/jobs/table/JobExpectedCostLineOrdersTable.vue";
import JobExpectedCostLineOrderModal from "@/components/jobs/modal/JobExpectedCostLineOrderModal.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";

export default {
  name: "JobExpectedCostLinesTable",
  components: {
    BaseSelect,
    JobExpectedCostLineOrderModal,
    JobExpectedCostLineOrdersTable,
  },
  props: {
    linesData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collapse: true,
      loading: false,
      categoryKey: null,
      revisionTypeKey: null,
      modalVisible: false,
      expectedCostLineId: null,
      fields: [
        {
          label: "FECHA",
          key: "expected_cost_date",
          sortable: false,
          thStyle: { "min-width": "160px" },
        },
        {
          label: "DESCRIPCIÓN",
          key: "description",
          thStyle: { "min-width": "160px" },
          sortable: false,
        },
        {
          label: "PREV. INICIAL",
          key: "initial_prevision",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "PREV. VIVA",
          key: "prevision",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "DESVIACION",
          key: "deviation",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "PEDIDO",
          key: "order",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "PENDIENTE",
          key: "pendent",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { "min-width": "120px" },
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: { "max-width": "300px" },
          tdClass: "text-right",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("job", {
      job: "getJob",
      isMaintenance: "getIsMaintenance",
      readonly: "getIsReadOnlyMode",
    }),
    name() {
      return this.linesData?.name || "Sin nombre";
    },
    lines() {
      return (
        this.linesData?.lines.map((line) => {
          return {
            ...line,
            category_group: {
              id: this.linesData.id,
              name: this.linesData.name,
            },
          };
        }) || []
      );
    },
    totalInitialPrevision() {
      return `${this.$options.filters.numberToLocalString(
        this.linesData?.total_initial_prevision
      )} €`;
    },
    totalPrevision() {
      return `${this.$options.filters.numberToLocalString(
        this.linesData?.total_prevision
      )} €`;
    },
    totalOrder() {
      return `${this.$options.filters.numberToLocalString(
        this.linesData?.total_order
      )} €`;
    },
    totalDeviation() {
      return `${this.$options.filters.numberToLocalString(
        this.linesData?.total_deviation
      )} €`;
    },
    totalPendent() {
      return `${this.$options.filters.numberToLocalString(
        this.linesData?.total_pendent
      )} €`;
    },
    isSuperAdmin() {
      return (
        JSON.parse(localStorage.getItem("userData")).roles[0].name ===
        "super_admin"
      );
    },
    rowsEditionEnabled: {
      get() {
        return this.$store.getters["job/getRowsEditionEnabled"];
      },
      set({ index, value }) {
        this.$store.commit("job/setRowsEditionEnabled", { index, value });
      },
    },
  },
  watch: {
    collapse: {
      handler(value) {
        if (value) {
          this.linesData.lines.forEach((line, index) => {
            if (line._rowVariant && line._rowVariant === "warning") {
              this.rowsEditionEnabled = { index, value: true };
            }
          });
        }
      },
      imediate: true,
    },
  },
  methods: {
    ...mapActions("job", ["loadJobExpectedCostLines", "loadJobEconomicState"]),
    loadListRevisionType() {
      this.$refs["revision-type"].loadData();
    },
    async handleSaveIconClick(row) {
      const expectedCostLine = row.item;
      if (!expectedCostLine) {
        return;
      }
      if (!expectedCostLine.category_group) {
        expectedCostLine.category_group = {
          id: this.linesData.id,
          name: this.linesData.name,
        };
      }
      this.loading = true;
      try {
        await JobExpectedCostLinesApi.edit(expectedCostLine.id, {
          ...expectedCostLine,
          job_id: this.job.id,
        });
        await this.loadJobData();
      } finally {
        this.loading = false;
        this.rowsEditionEnabled = { index: expectedCostLine.id, value: false };
      }
    },
    async handleDeleteIconClick(expectedCostLineId) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará la linea",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await JobExpectedCostLinesApi.delete(expectedCostLineId);
        await this.loadJobData();
      } finally {
        this.loading = false;
      }
    },
    handleNewOrderButtonClick(expectedCostLineId) {
      this.expectedCostLineId = expectedCostLineId;
      this.modalVisible = true;
    },
    async handleCreateOrder(provider) {
      this.loading = true;
      try {
        const response =
          await JobExpectedCostLinesApi.createOrderByJobExpectedCostLine(
            this.expectedCostLineId,
            provider
          );
        await this.$router.push({
          name: "viewOrder",
          params: { id: response.data.id },
          query: { "job-id": this.job.id },
        });
      } finally {
        this.loading = false;
      }
    },
    loadJobData() {
      return Promise.all([
        this.loadJobExpectedCostLines(this.job.id),
        this.loadJobEconomicState(this.job.id),
      ]);
    },
    handleRowClick(row) {
      if (row.orders.length === 0) {
        return;
      }

      // eslint-disable-next-line no-underscore-dangle
      this.$set(row, "_showDetails", !row._showDetails);
    },
    handleRowHovered(line) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      line._rowVariant = "";
    },
    setCollapse(collapse) {
      this.collapse = collapse;
    },
  },
};
</script>

<style scoped>
.collapse-icon-container {
  width: 13px;
  display: inline-block;
}

.job-expected-cost-lines-table-container {
  border-bottom: 1px solid #ebe9f1;
}
.container-actions{
  width: 7rem;
}
.container-collapse{
  width: 1rem;
}
</style>
