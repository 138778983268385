<template>
  <b-card
    no-body
    class="rounded-top"
  >
    <div class="font-weight-bold text-uppercase p-2">
      previsión económica del job
    </div>
    <b-table-simple
      responsive
      :bordered="true"
      class="mb-0"
    >
      <colgroup>
        <col
          v-for="(_, colGroupIndex) in row"
          :key="colGroupIndex"
          :style="{ 'min-width': '180px' }"
        >
      </colgroup>
      <b-tr class="bg-table-headings text-uppercase text-right">
        <b-th
          v-for="(field, fieldLabelIndex) in row"
          :key="fieldLabelIndex"
          class="font-small-1"
        >
          {{ field.label }}
        </b-th>
      </b-tr>
      <b-tr>
        <b-td
          v-for="(field, index) in row"
          :key="index"
          :class="'text-right ' + (field.fieldClass || '')"
        >
          {{ economicPrevision[field.key] | numberToLocalString }} {{ field.unit }}
        </b-td>
      </b-tr>
    </b-table-simple>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'JobEconomicPrevisionTable',
  data() {
    return {
      row:
        [
          { label: 'venta inicial', key: 'total_initial_sale', unit: '€' },
          {
            label: 'adicionales', key: 'total_additionals', unit: '€', fieldClass: 'bg-light-yellow',
          },
          { label: 'venta final', key: 'final_sale', unit: '€' },
          {
            label: 'total costes', key: 'total_costs', unit: '€', fieldClass: 'bg-light-yellow',
          },
          { label: 'beneficio', key: 'benefit', unit: '€' },
          { label: 'margen', key: 'margin', unit: '%' },
        ],
    }
  },
  computed: {
    ...mapGetters('job', {
      jobEconomicState: 'getJobEconomicState',
    }),
    economicPrevision() {
      return this.jobEconomicState?.economic_prevision || {}
    },
  },
}
</script>

<style scoped>

</style>
