<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-table
      hover
      responsive
      fixed
      :fields="fields"
      :items="purchaseInvoices"
      :no-local-sorting="true"
      @row-clicked="$router.push({ name: 'viewPurchaseInvoice', params: { id: $event.id }, query: { origin:'invoice' } })"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{'width': field.key === 'status' ? '210px' : '150px' }"
        >
      </template>
      <template #cell(order)="data">
        {{ data.value.number }}
      </template>
      <template #cell(total_gross_cy)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(total_retention_cy)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(total_invoice_cy)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(invoice_date)="data">
        {{ data.value | formatDate }}
      </template>
      <template #cell(status)="data">
        <StatusBadge :status="data.value.name" />
      </template>
      <template #cell(provider)="data">
        {{ data.value ? data.value.name : '' }}
      </template>
      <template #cell(documents)="data">
        <b-link
          v-if="data.value && data.value.length > 0"
          class="text-decoration-none text-indigo"
          @click="handleDownload(data.value[0])"
        >
          <u>{{ data.value[0].name || 'Adjunto' }}</u>
        </b-link>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ApiRestService from '@/api/base-api'

export default {
  name: 'JobPurchaseInvoicesTable',
  components: { StatusBadge },
  props: {
    purchaseInvoices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          label: 'Nº Factura proveedor',
          key: 'provider_code',
          sortable: false,
          tdClass: 'px-2',
          thClass: 'px-2',
        },
        {
          label: 'FECHA',
          key: 'invoice_date',
          sortable: false,
        },
        {
          label: 'Nº PEDIDO',
          key: 'order',
          sortable: false,
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: false,
        },
        {
          label: 'PROVEEDOR',
          key: 'provider',
          sortable: false,
        },
        {
          label: 'DOC. ADJUNTO',
          key: 'documents',
          sortable: false,
        },
        {
          label: 'TOTAL BRUTO',
          key: 'total_gross_cy',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'TOTAL RETENCIÓN',
          key: 'total_retention_cy',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'TOTAL',
          key: 'total_invoice_cy',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    }
  },
  methods: {
    async handleDownload(file) {
      this.loading = true
      try {
        await ApiRestService.downloadArchive(file.id, file.name)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
