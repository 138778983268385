<template>
  <b-card no-body>
    <b-row
      align-h="between"
      align-v="center"
      class="p-2"
    >
      <b-col
        cols="auto"
        class="text-gray-50 font-weight-bold name-width"
      >
        <span class="text-uppercase">no conformidades</span>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-if="!readonly"
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          @click="$router.push({ name: 'createNonConformity', query: { 'job-id': jobId } })"
        >
          Nueva no conformidad
        </b-button>
      </b-col>
    </b-row>
    <NonConformitiesTable
      :job-id="jobId"
      :exclude-columns="['origin', 'issuer', 'action_type', 'implementation_period', 'non_conformity_date_close']"
      hide-toolbar
      disable-pagination
    />
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import NonConformitiesTable from '@/components/non-conformities/table/NonConformitiesTable.vue'

export default {
  name: 'JobNonConformitiesCard',
  components: { NonConformitiesTable },
  props: {
    jobId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
      readonly: 'getIsReadOnlyMode',
    }),
  },
}
</script>

<style scoped>
</style>
