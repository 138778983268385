<template>
  <JobLinesCard
    ref="job-additional-lines-card"
    :job-id="jobId"
    :job-lines="jobAdditionalLines"
    :totals="jobAdditionalLinesTotals"
    :totals-configuration="[{ label: 'venta total', key: 'total_sale'}, { label: 'coste total', key: 'total_cost' }]"
    :loading="loading"
    title="adicionales previstos"
    add-button-text="+ Nuevo adicional"
    @create-line="handleCreateLine"
  >
    <template v-slot:default="{ linesData }">
      <JobAdditionalLinesTable
        :ref="jobAdditionalTableReference + linesData.id"
        :lines-data="linesData"
        @expected-cost-line-created="$emit('expected-cost-line-created', $event)"
      />
    </template>
  </JobLinesCard>
</template>

<script>
import JobLinesCard from '@/components/jobs/card/JobLinesCard.vue'
import JobAdditionalLinesTable from '@/components/jobs/table/JobAdditionalLinesTable.vue'
import { mapActions, mapGetters } from 'vuex'
import ApiRestService from '@/api/base-api'

const JOB_ADDITIONAL_TABLE_REFERENCE = 'job-additional-lines-table-'

export default {
  name: 'JobAdditionalLinesCard',
  components: { JobAdditionalLinesTable, JobLinesCard },
  props: {
    jobId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      jobAdditionalTableReference: JOB_ADDITIONAL_TABLE_REFERENCE,
    }
  },
  computed: {
    ...mapGetters('job', {
      jobAdditionalLines: 'getJobAdditionalLines',
      jobAdditionalLinesTotals: 'getJobAdditionalLinesTotals',
      contractSelected: 'getContractSelected',
      isMaintenance: 'getIsMaintenance',
    }),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('job', ['loadJobAdditionalLines', 'loadJobExpectedCostLines', 'loadJobEconomicState', 'setAdditionalLineRowVariant']),
    async fetchData() {
      if (!this.jobId) {
        return
      }

      this.loading = true
      try {
        await this.loadJobAdditionalLines(this.jobId)
      } finally {
        this.loading = false
      }
    },
    async handleCreateLine(line) {
      if (!this.jobId) {
        return
      }

      let params = line
  
      if(this.isMaintenance) {
        params = { ...line, job_contract_id: this.contractSelected(this.jobId).contract.id }
      }
      

      try {
        const response = await ApiRestService.create(params, this.$data.$constants.RESOURCES.RESOURCE_JOBS_ADDITIONAL_LINES, false)
        const newLine = response.data
        this.setCollapseLine(newLine.category_group.id, true)
        await Promise.all([
          this.loadJobAdditionalLines(this.jobId),
          this.loadJobEconomicState(this.jobId),
        ])
        this.setCollapseLine(newLine.category_group.id, true)
        this.setAdditionalLineRowVariant({
          categoryGroupId: newLine.category_group.id, lineId: newLine.id, rowVariant: 'warning',
        })
        this.collapseAllExcept(newLine.category_group.id)
        document.getElementById(`job-additional-lines-${newLine.category_group.id}`).scrollIntoView({
          behavior: 'smooth',
        })
        this.$toast('Recurso actualizado con éxito.')
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde.')
      }

      this.loading = false
    },
    collapseAllExcept(excludedLineReference) {
      const lines = this.jobAdditionalLines.filter(jobAdditionalLine => jobAdditionalLine.id !== excludedLineReference)
      lines.forEach(jobAdditionalLine => {
        this.setCollapseLine(jobAdditionalLine.id, false)
      })
    },
    setCollapseLine(lineReference, collapse) {
      const jobAdditional = this.$refs[this.jobAdditionalTableReference + lineReference]
      if (jobAdditional) {
        jobAdditional.setCollapse(collapse)
      }
    },
  },
}
</script>

<style scoped>
</style>
