<template>
  <div>
    <b-card no-body>
      <b-row align-h="between" align-v="center" class="p-2">
        <b-col cols="auto" class="text-gray-50 font-weight-bold name-width">
          <span class="text-uppercase">certificaciones</span>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="rounded-sm px-2"
            @click="handleNewCertificationButtonClick"
          >
            Generar nueva certificación
          </b-button>
        </b-col>
      </b-row>
      <MaintenanceCertificationsTable 
        ref="maintenance-certifications-table" 
        :jobId="jobId" 
        :hideToolbar="true" 
      />
    </b-card>
  </div>
</template>

<script>
import MaintenanceCertificationsTable from '@/components/maintenance-certifications/table/MaintenanceCertificationsTable.vue'
import MaintenanceCertificationsApi from "@/api/maintenance-certifications";

export default {
  name: "JobMaintenanceCertificationsCard",
  components: { MaintenanceCertificationsTable },
  props: {
    jobId: {
      type: String,
      default: "",
    },
  },
  methods: {
    async handleNewCertificationButtonClick() {
      try {
        const job_contract_id = this.$store.getters['job/getContractSelected'](this.jobId).contract?.id


        let params = {
          job_id: this.jobId
        }

        if(job_contract_id) {
          params.job_contract_id = job_contract_id
        }
  
        await MaintenanceCertificationsApi.create(params);
        await this.$refs["maintenance-certifications-table"].loadData();
      } finally {
        // this.loading = false;
      }
    },
  }
};
</script>
