<template>
  <div>
    <BaseTable
      ref="non-conformities-table"
      :columns="computedColumns"
      :filters="baseFilters"
      :excluded-filters="excludeFilters"
      :hide-totals="hideTotals"
      resource="non-conformities"
      :resourceStore="'nonConformities'"
      :loading="loading"
      :disable-pagination="disablePagination"
      :hide-toolbar="hideToolbar"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="$router.push({ name: 'viewNonConformity', params: { id: $event.id } })"
    >
      <template #cell(non_conformity_date)="data">
        {{ data.value | formatDate }}
      </template>
      <template #cell(origin)="data">
        {{ data.value? data.value.name : '' }}
      </template>
      <template #cell(issuer)="data">
        {{ data.value? data.value.name : '' }}
      </template>
      <template #cell(non_conformity_date_close)="data">
        {{ data.value | formatDate }}
      </template>
      <template #cell(status)="data">
        <StatusBadge :status="data.value.name" />
      </template>
      <template #cell(actions)="data">
        <span>
          <b-link
            :to="{ name: 'viewNonConformity', params: { id: data.item.id} }"
            class="pr-1 d-inline-block text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Ver"
              icon="EyeIcon"
              size="18"
            />
          </b-link>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            class="d-inline-block text-danger"
            @click="handleDeleteIconClick(data.item)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </b-link>
        </span>
      </template>
    </BaseTable>
    <NonConformitiesListFilters
      ref="non-conformities-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('nonConformities/setFilters', $event)"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/ui/table/BaseTable.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import NonConformitiesListFilters from '@/components/non-conformities/filters/NonconformitiesListFilters.vue'
import NonConformitiesApi from '@/api/non-conformities-api'
import { mapGetters } from 'vuex'

export default {
  name: 'NonConformitiesTable',
  components: { NonConformitiesListFilters, StatusBadge, BaseTable },
  props: {
    jobId: {
      type: String,
      default: null,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    excludeColumns: {
      type: Array,
      default: () => [],
    },
    hideTotals: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      filtersVisible: false,
      columns: [
        {
          label: 'NÚMERO',
          key: 'number',
          sortable: true,
          thClass: 'px-2',
          tdClass: 'px-2',
        },
        {
          label: 'FECHA DE APERTURA',
          key: 'non_conformity_date',
          sortable: true,
        },
        {
          label: 'ORIGEN',
          key: 'origin',
          sortable: true,
        },
        {
          label: 'RECLAMACIÓN/SUGERENCIA',
          key: 'issuer',
          sortable: true,
        },
        {
          label: 'RESUMEN',
          key: 'resume',
          sortable: true,
        },
        {
          label: 'RESPONSABLE',
          key: 'implementation_resp',
          sortable: true,
        },
        {
          label: 'PLAZO DE IMPLEMENTACIÓN',
          key: 'implementation_period',
          sortable: true,
        },
        {
          label: 'FECHA CIERRE',
          key: 'non_conformity_date_close',
          sortable: true,
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Acciones',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '200px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filters: 'nonConformities/getFilters',
    }),
    baseFilters() {
      return { job_id: this.jobId, ...this.filters }
    },
    excludeFilters() {
      const result = []
      if (this.jobId) {
        result.push('job_id')
      }

      return result
    },
    computedColumns() {
      return this.columns.filter(column => !this.excludeColumns.includes(column.key))
    },
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit('nonConformities/setFilters', {})
      this.$refs['non-conformities-list-filters'].clearFilters()
    },
    async handleDeleteIconClick(nonConformity) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await NonConformitiesApi.delete(nonConformity.id)
        await this.$refs['non-conformities-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
