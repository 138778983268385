<template>
  <div
    :id="`job-additional-lines-${linesData.id}`"
    class="job-additional-lines-table-container"
  >
    <b-row
      align-h="between"
      align-v="center"
      class="p-2 cursor-pointer"
      @click="collapse = !collapse"
    >
      <b-col
        class="text-gray-50 font-weight-bold font-small"
        cols="auto"
      >
        <span class="text-uppercase">{{ name }}</span>
      </b-col>
      <b-col cols="auto">
        <FeatherIcon
          v-if="!collapse"
          icon="ChevronDownIcon"
          class="text-primary"
        />
        <FeatherIcon
          v-else
          icon="ChevronUpIcon"
          class="text-primary"
        />
      </b-col>
    </b-row>
    <b-collapse v-model="collapse">
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-table
          hover
          responsive
          :fields="fields"
          :items="lines"
          :no-local-sorting="true"
          @row-hovered="handleRowHovered($event)"
        >
          <template #cell(additional_date)="data">
            <b-form-datepicker
              v-if="rowsEditionEnabled[data.index]"
              v-model="data.item.additional_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder=""
              size="sm"
              reset-button
              :hide-header="true"
              :label-help="null"
              boundary="viewport"
              start-weekday="1"
            />
            <span v-else>
              {{ data.item.additional_date | formatDate }}
            </span>
          </template>
          <template #cell(product)="data">
            <div class="mb-2">
              <ProductSelect
                v-if="rowsEditionEnabled[data.index]"
                v-model="data.item.product"
                :category-group-id="data.item.category_group_id"
              />
              <span v-else>{{ data.item.product ? data.item.product.name : 'Sin producto' }}</span>
            </div>
            <div>
              <b-form-input
                v-if="rowsEditionEnabled[data.index]"
                v-model="data.item.description"
              />
              <span v-else>{{ data.item.description }}</span>
            </div>
          </template>
          <template #cell(offer)="data">
            <BaseSelect
              v-if="rowsEditionEnabled[data.index]"
              id="offer"
              v-model="data.item.offer"
              label="label"
              :resource="$data.$constants.RESOURCES.RESOURCE_OFFERS"
            />
            <span v-else>
              {{ data.item.offer ? data.item.offer.label : '' }}
            </span>
          </template>
          <template #cell(units)="data">
            <b-form-input
              v-if="rowsEditionEnabled[data.index]"
              v-model="data.item.units"
              type="number"
            />
            <span v-else>{{ data.item.units }}</span>
          </template>
          <template #cell(amount)="data">
            <b-input-group
              v-if="rowsEditionEnabled[data.index]"
              class="input-group-merge"
            >
              <b-form-input
                v-model="data.item.amount"
                type="number"
              />
              <b-input-group-append is-text>
                €
              </b-input-group-append>
            </b-input-group>
            <span v-else>{{ data.item.amount | numberToLocalString }} €</span>
          </template>
          <template #cell(cost)="data">
            <b-input-group
              v-if="rowsEditionEnabled[data.index]"
              class="input-group-merge"
            >
              <b-form-input
                v-model="data.item.cost"
                type="number"
              />
              <b-input-group-append is-text>
                €
              </b-input-group-append>
            </b-input-group>
            <span v-else>{{ data.item.cost | numberToLocalString }} €</span>
          </template>
          <template #cell(total_cost)="data">
            {{ data.item.total_cost | numberToLocalString }} €
          </template>
          <template #cell(total_sale)="data">
            {{ data.item.total_sale | numberToLocalString }} €
          </template>
          <template #cell(status)="data">
            <StatusSelect
              v-if="rowsEditionEnabled[data.index]"
              v-model="data.item.status"
              class="font-small-3 d-inline-block"
              :clearable="false"
              :type="statusType"
            />
            <StatusBadge
              v-else-if="data.item.status"
              :status="data.item.status.name"
            />
          </template>
          <template #cell(actions)="data">
            <b-link
              v-if="!rowsEditionEnabled[data.index]"
              class="d-inline-block text-indigo pr-1"
              @click="handleEditIconClick(data)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Editar"
                icon="Edit2Icon"
                size="18"
              />
            </b-link>
            <b-link
              v-else
              class="d-inline-block text-indigo pr-1"
              @click="saveLine(data.item, data.index)"
            >
              <feather-icon
                icon="SaveIcon"
                size="18"
              />
            </b-link>
            <BaseFileInput
              ref="jod-additional-file-input"
              class="d-inline"
              :multiple="false"
              @change="handleJobAdditionalInputChange($event, data.item, data.index)"
            >
              <b-link class="d-inline-block text-indigo pr-1">
                <feather-icon
                  icon="UploadIcon"
                  size="18"
                />
              </b-link>
            </BaseFileInput>
            <b-link
              class="d-inline-block text-danger"
              @click="handleDeleteIconClick(data.item.id)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </b-link>
            <div v-if="data.item.documents.length > 0" class="d-flex pt-1">
              <b-link
                class="text-indigo d-block"
                @click="handleJobAdditionalDocumentDownload(data.item.documents)"
              >
                {{ data.item.documents[0].name }}
              </b-link>
              <b-link @click="handleDeleteDocument(data.item.id, data.item.documents[0].id)">
                <span
                  class="d-inline-block text-danger"
                >
                  <feather-icon
                    v-b-tooltip.hover 
                    title="Eliminar"
                    icon="TrashIcon"
                    size="18"
                  />
                </span>
              </b-link>
            </div>
          </template>
          <template #custom-foot>
            <b-tr>
              <b-td colspan="4" />
              <b-td class="text-right text-uppercase font-weight-bold">
                Totales:
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                {{ totalSale }}
              </b-td>
              <b-td class="text-uppercase font-weight-bold text-right">
                {{ totalCost }}
              </b-td>
              <b-td colspan="3" />
            </b-tr>
          </template>
        </b-table>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue'
import { JOB_ADDITIONALS_STATUS_TYPE } from '@/api/status-api'
import ApiRestService from '@/api/base-api'
import { mapActions, mapGetters } from 'vuex'
import ProductSelect from '@/components/products/select/ProductSelect.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import BaseFileInput from '@/components/ui/file/BaseFileInput.vue'
import JobsAdditionalLinesApi from '@/api/job-aditional-lines-api'

export default {
  name: 'JobAdditionalLinesTable',
  components: {
    BaseFileInput,
    BaseSelect,
    StatusBadge,
    StatusSelect,
    ProductSelect,
  },
  props: {
    linesData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collapse: false,
      loading: false,
      statusType: JOB_ADDITIONALS_STATUS_TYPE,
      rowsEditionEnabled: [],
      transProps: {
        name: 'list',
      },
      fields: [
        {
          label: 'FECHA',
          key: 'additional_date',
          sortable: false,
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'PRODUCTO',
          key: 'product',
          sortable: false,
          thStyle: { 'min-width': '150px' },
        },
        {
          label: 'UNIDADES',
          key: 'units',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: false,
          thStyle: { width: '50px' },
        },
        {
          label: 'IMPORTE UNIDAD',
          key: 'amount',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'COSTE UNIDAD',
          key: 'cost',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'VENTA TOTAL',
          key: 'total_sale',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'COSTE TOTAL',
          key: 'total_cost',
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'OFERTA',
          key: 'offer',
          sortable: false,
          thStyle: { 'min-width': '120px' },
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '190px' },
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          thStyle: { 'min-width': '110px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
    }),
    name() {
      return this.linesData?.name || 'Sin nombre'
    },
    lines() {
      return this.linesData?.lines || []
    },
    totalCost() {
      return `${this.$options.filters.numberToLocalString(this.linesData?.total_cost)} €`
    },
    totalSale() {
      return `${this.$options.filters.numberToLocalString(this.linesData?.total_sale)} €`
    },
  },
  methods: {
    ...mapActions('job', ['loadJobAdditionalLines', 'loadJobEconomicState', 'loadJobExpectedCostLines']),
    async saveLine(additionalLine, rowIndex) {
      this.loading = true
      try {
        const line = { ...additionalLine, job_id: this.job.id, category_group_id: this.linesData.id }
        await ApiRestService.edit(
          line.id,
          line,
          this.$data.$constants.RESOURCES.RESOURCE_JOBS_ADDITIONAL_LINES,
        )
        if (additionalLine.status?.name.toLowerCase() === 'contratado') {
          await this.generateExpectedCost(line)
        }
        await this.loadJobData()
      } finally {
        this.loading = false
        Vue.set(this.rowsEditionEnabled, rowIndex, false)
      }
    },
    async handleDeleteIconClick(additionalLineId) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará la linea',
      })

      if (!response.isConfirmed) {
        return
      }

      if (this.linesData.lines.length === 1) {
        this.collapse = false
      }

      this.loading = true
      try {
        await ApiRestService.delete(additionalLineId, this.$data.$constants.RESOURCES.RESOURCE_JOBS_ADDITIONAL_LINES)
        await this.loadJobData()
      } finally {
        this.loading = false
      }
    },
    async handleDeleteDocument(additionalLineId, documentId) {
      this.loading = true
      try {
        await JobsAdditionalLinesApi.deleteDocument(additionalLineId, documentId)
        await this.loadJobData()
      } finally {
        this.loading = false
      }
    },
    loadJobData() {
      return Promise.all([
        this.loadJobAdditionalLines(this.$route.params.id),
        this.loadJobEconomicState(this.$route.params.id),
      ])
    },
    async generateExpectedCost(line) {
      const modal = await this.$modal.show({
        title: 'Este adicional será sumado al valor de la venta total.',
        text: '¿Quieres añadir un nuevo coste previsto?',
        confirmButtonText: 'Añadir coste previsto',
        cancelButtonText: 'No, continuar con el adicional',
        width: '40em',
      })

      if (!modal.isConfirmed) {
        return
      }

      ApiRestService.create(line, this.$data.$constants.RESOURCES.RESOURCE_JOBS_EXPECTED_COST_LINES, false)
        .then(async (response) => {
          await this.loadJobExpectedCostLines(/* this.job.id */ this.$route.params.id),
          this.$emit('expected-cost-line-created', response.data)
        })
      // const response = await Promise.all([
      //   this.loadJobExpectedCostLines(/* this.job.id */ this.$route.params.id),
      // ])

    },
    handleEditIconClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, true)
    },
    setCollapse(collapse) {
      this.collapse = collapse
    },
    handleRowHovered(line) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      line._rowVariant = ''
    },
    handleJobAdditionalInputChange(file, additionalLine, rowIndex) {
      this.saveLine({ ...additionalLine, documentation: file }, rowIndex)
    },
    async handleJobAdditionalDocumentDownload(document) {
      if (!document || document.length === 0) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.downloadArchive(document[0].id, document[0].name)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.job-additional-lines-table-container {
  border-bottom: 1px solid #ebe9f1;
}
</style>
