<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <h1 class="font-large-1 text-center pt-2">
        Selecciona la categoría
      </h1>
      <validation-observer ref="job-additional-line-form">
        <b-form
          class="py-2"
          @submit.prevent
        >
          <b-row align-h="center">
            <b-col
              sm="12"
              lg="10"
            >
              <validation-provider
                v-slot="validationContext"
                name="Categoría"
                rules="required"
              >
                <b-form-group
                  label="Categoría"
                  label-for="job-category"
                >
                  <CategoryGroupSelect
                    id="job-category"
                    v-model="categoryGroup"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'

export default {
  name: 'JobLineCreationModal',
  components: { CategoryGroupSelect },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      categoryGroup: null,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async handleAcceptButtonClick() {
      const valid = await this.$refs['job-additional-line-form'].validate()
      if (!valid) {
        return
      }

      this.$emit('create-line', { category_group_id: this.categoryGroup.id, job_id: this.jobId })
    },
  },
}
</script>

<style scoped>
</style>
