var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"white","spinner-variant":"primary","rounded":"sm"}},[_c('b-tabs',{key:("" + _vm.keyTabs),attrs:{"lazy":"","pills":""},on:{"activate-tab":_vm.handleActivateTab},scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_c('b-button',{staticClass:"custom-tab ml-1",attrs:{"disabled":_vm.job && _vm.job.division.reference_code === 'M',"variant":"outline-light","size":"sm","pill":""},on:{"click":_vm.downloadAssists}},[_c('span',[_vm._v(" Asistencias ")])])]},proxy:true}])},[_c('b-tab',{staticClass:"pb-1",attrs:{"title":"Estado económico","active":_vm.lastOpenedTab === 0}},[_c('JobEconomicAdvanceTable',{attrs:{"loading":_vm.loading}}),_c('JobEconomicStateTable'),_c('JobEconomicPrevisionTable'),_c('JobAdditionalLinesCard',{attrs:{"job-id":_vm.jobId},on:{"expected-cost-line-created":_vm.handleExpectedCostLineCreated}}),_c('JobExpectedCostLinesCard',{ref:"job-expected-cost-lines-card",attrs:{"job-id":_vm.jobId}})],1),_c('b-tab',{attrs:{"title":"Pedido","active":_vm.lastOpenedTab === 1}},[_c('JobOrdersCard',{attrs:{"title":"totales globales","totals":_vm.jobOrders.totals,"bg-class":"bg-action-blue"}}),_vm._l((_vm.jobOrders.data),function(ref,index){
var count = ref.count;
var total_gross_order = ref.total_gross_order;
var total_billing = ref.total_billing;
var total_pending_billing = ref.total_pending_billing;
var lines = ref.lines;
var name = ref.name;
return _c('JobOrdersCard',{key:index,attrs:{"title":name,"totals":{ count: count, total_gross_order: total_gross_order, total_billing: total_billing, total_pending_billing: total_pending_billing },"collapse-enabled":""}},[_c('JobOrdersTable',{attrs:{"orders":lines || []}})],1)})],2),_c('b-tab',{attrs:{"title":"Fra. Proveedores","active":_vm.lastOpenedTab === 2}},[_c('JobPurchaseInvoicesCard',{attrs:{"title":"totales globales","totals":_vm.jobPurchaseInvoicesTotals,"bg-class":"bg-action-blue"}}),_vm._l((_vm.jobPurchaseInvoices.data),function(ref,index){
var count = ref.count;
var total_gross = ref.total_gross;
var total_invoice = ref.total_invoice;
var total_retention = ref.total_retention;
var lines = ref.lines;
var name = ref.name;
return _c('JobPurchaseInvoicesCard',{key:index,attrs:{"title":name,"totals":{ count: count, total_gross: total_gross, total_invoice: total_invoice, total_retention: total_retention },"collapse-enabled":""}},[_c('JobPurchaseInvoicesTable',{attrs:{"purchase-invoices":lines || []}})],1)})],2),(!_vm.showMaintenanceTable)?_c('b-tab',{attrs:{"disabled":!_vm.canAccessResource(_vm.$data.$constants.RESOURCES.RESOURCE_CERTIFICATIONS, _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL),"title":"Certificaciones","active":_vm.lastOpenedTab === 3}},[_c('JobCertificationsCard',{attrs:{"job-id":_vm.jobId}})],1):_vm._e(),(_vm.showMaintenanceTable)?_c('b-tab',{attrs:{"title":"Certificaciones","active":_vm.lastOpenedTab === 3}},[_c('JobMaintenanceCertificationsCard',{attrs:{"jobId":_vm.jobId}})],1):_vm._e(),_c('b-tab',{attrs:{"title":"Fra. Emitidas","active":_vm.lastOpenedTab === 4}},[_c('JobSaleInvoicesCard',{attrs:{"job-id":_vm.jobId}})],1),_c('b-tab',{attrs:{"disabled":!_vm.canAccessResource(_vm.$data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES, _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL),"title":"No conformidades","active":_vm.lastOpenedTab === 5}},[_c('JobNonConformitiesCard',{attrs:{"job-id":_vm.jobId}})],1),(_vm.isMaintenance)?_c('b-tab',{attrs:{"title":"Alcance oferta","disabled":!_vm.contractSelected.contract,"active":_vm.lastOpenedTab === 6}},[_c('RaterCalculationTable',{attrs:{"jobId":_vm.jobId}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }