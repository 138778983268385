<template>
  <b-card no-body class="rounded-top">
    <div class="font-weight-bold text-uppercase p-2">estado económico</div>
    <div style="overflow-y: auto !important">
      <b-table-simple
        v-for="(row, rowIndex) in rows"
        :key="rowIndex"
        :bordered="true"
        class="mb-0"
      >
        <!-- responsive -->
        <!-- fixed -->
        <!-- <colgroup>
        <col
        v-for="(_, colGroupIndex) in row"
        :key="colGroupIndex"
        :style="'min-width: 180px'"
        >
      </colgroup> -->
        <b-tr class="bg-table-headings text-uppercase text-right">
          <b-th
            v-for="(field, fieldLabelIndex) in row"
            :key="fieldLabelIndex"
            class="font-small-1"
            style="min-width: 220px !important; max-width: 220px !important"
          >
            {{ field.label }}
          </b-th>
        </b-tr>
        <b-tr>
          <template v-for="(field, index) in row">
            <b-td
              v-if="field.key !== 'disabled'"
              :key="index"
              :class="'text-right ' + (field.fieldClass || '')"
              style="min-width: 220px !important; max-width: 220px !important"
            >
              {{
                field.key ? economicStatus[field.key] : "" | numberToLocalString
              }}
              {{ field.unit }}
            </b-td>
            <b-td
              v-else
              style="min-width: 220px !important; max-width: 220px !important"
            ></b-td>
          </template>
        </b-tr>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JobEconomicStateTable",
  data() {
    return {
      rows: [
        [
          { label: "facturas emitidas", key: "issued_invoices", unit: "€" },
          {
            label: "% facturado",
            key: "billed_percentage",
            unit: "%",
            fieldClass: "bg-light-yellow",
          },
          { label: "facturas recibidas", key: "received_invoices", unit: "€" },
          {
            label: "%facturado (avance job)",
            key: "invoiced_percentage_advance_job",
            unit: "%",
            fieldClass: "bg-light-yellow",
          },
          { label: "ias", key: "ias", unit: "€" },
          {
            label: "total valor factura por emitir",
            key: "invoices_to_be_issued",
            unit: "€",
          },
          {
            label: "total valor factura por recibir",
            key: "invoices_to_receive",
            unit: "€",
          },
        ],
        [
          { label: "facturas cobradas", key: "invoices_collected", unit: "€" },
          { label: "% cobrado", key: "percent_collect", unit: "%" },
          { label: "% pagado", key: "percent_paid", unit: "%" },
          { label: "facturas pagadas", key: "invoices_paid", unit: "€" },
          { label: "caja job", key: "job_box", unit: "€" },
          { label: "", key: "disabled", unit: "" },
          { label: "", key: "disabled", unit: "" },
        ],
      ],
    };
  },
  computed: {
    ...mapGetters("job", {
      jobEconomicState: "getJobEconomicState",
    }),
    economicStatus() {
      return this.jobEconomicState?.economic_status || {};
    },
  },
};
</script>

<style scoped></style>
