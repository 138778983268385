<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>
            Fecha entre
          </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.certification_date_min"
                class="margin-right-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.certification_date_max"
                @input="update('certification_date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.certification_date_max"
                class="margin-left-datepicker"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.certification_date_min"
                @input="update('certification_date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Cliente"
          label-for="certification-client"
        >
          <BaseSelect
            id="certification-client"
            :value="model.client"
            :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
            @input="update('client', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Job"
          label-for="certification-job"
        >
          <BaseSelect
            id="certification-job"
            :value="model.job"
            :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
            @input="update('job', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Estado"
          label-for="certification-status"
        >
          <StatusSelect
            id="certification-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { MAINTENANCE_CERTIFICATIONS_TYPE } from '@/api/status-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'CertificationsListFiltersForm',
  components: {
    BaseSelect,
    StatusSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: MAINTENANCE_CERTIFICATIONS_TYPE,
    }
  },
}
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
