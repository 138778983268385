<template>
  <BaseModal
    v-model="model"
    size="md"
    :loading="loading"
    :showFooter="false"
  >
    <template v-if="!noResponseView">
      <h1 class="font-large-1 text-center pt-2">¿Actualizar condiciones?</h1>
      <div class="d-flex justify-content-center">
        <div>
          <div>
            <b-button
              variant="primary"
              block
              class="rounded-sm px-2 my-1"
              @click="offerRevision"
            >
              Si
            </b-button>
          </div>
          <div>
            <b-button
              variant="primary"
              block
              class="rounded-sm px-2 my-1"
              @click="noResponseView = true"
            >
              No
            </b-button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="noResponseView">
      <div>
        <validation-observer ref="duration-input">
          <validation-provider
            v-slot="validationContext"
            name="duracion del contrato"
            rules="required"
          >
          <b-form-group
            label="Selecciona la duracion del contrato"
            label-for="contract-duration"
          >
            <BaseSelect
              id="contract-duration"
              http-method="get"
              :value="duration"
              :resource="$data.$constants.RESOURCES.RESOURCE_CONTRACT_DURATION"
              @input="duration = $event"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </b-form-group>
          </validation-provider>
        </validation-observer>
        <b-button
          variant="primary"
          block
          class="rounded-sm px-2 my-1"
          @click="renewContract"
        >
          Renovar
        </b-button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import JobContractsApi from '@/api/job-contracts-api'
import OffersApi from '@/api/offers-api'
import { mapGetters } from "vuex";

export default {
  name: "JobRenewContractModal",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BaseModal,
    BaseSelect,
  },
  data() {
    return {
      loading: false,
      noResponseView: false,
      duration:null,
    };
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          setTimeout(() =>  {
            this.noResponseView = false
            this.duration = null
          },
          200)
        }
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async offerRevision() {
      const { id, offer } = this.job
      const response = await OffersApi.check(offer.id)
      this.$router.push({ name: 'renewOfferJob', params: { id: response.data.id }, query: { job_id: id }})
    },
    async renewContract() {
      const validate = await this.$refs['duration-input'].validate()

      if (!validate) {
        return
      }

      this.loading = true
      const { id } = this.job
      try {
        await JobContractsApi.renewContract(id, { contract_duration: this.duration.id })
        window.location.reload()
      }  finally {
        this.loading = false
      }
    },
  },
};
</script>
