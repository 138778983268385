<template>
  <b-card
    no-body
    :class="'mb-1 ' + bgClass"
  >
    <b-row
      align-h="between"
      align-v="center"
      :class="'p-2 ' + (collapseEnabled ? 'cursor-pointer' : '')"
      @click="collapse = !collapse"
    >
      <b-col
        cols="auto"
        class="text-gray-50 font-weight-bold name-width"
      >
        <span class="text-uppercase">{{ title }}</span>
      </b-col>
      <b-col cols="auto">
        <span class="font-weight-bold">{{ ordersCount | numberToLocalString }}</span> Facturas recibidas
      </b-col>
      <b-col class="text-uppercase text-right">
        <div>total bruto</div>
        <div class="font-weight-bold">
          {{ totalGross | numberToLocalString }} €
        </div>
      </b-col>
      <b-col class="text-uppercase text-right">
        <div>total retención</div>
        <div class="font-weight-bold">
          {{ totalRetention | numberToLocalString }} €
        </div>
      </b-col>
      <b-col class="text-uppercase text-right">
        <div>total</div>
        <div class="font-weight-bold">
          {{ totalInvoice | numberToLocalString }} €
        </div>
      </b-col>
      <b-col
        cols="auto"
        class="collapse-col-width"
      >
        <template v-if="collapseEnabled">
          <FeatherIcon
            v-if="!collapse"
            icon="ChevronDownIcon"
            class="text-primary"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
            class="text-primary"
          />
        </template>
      </b-col>
    </b-row>
    <b-collapse v-model="collapse">
      <slot />
    </b-collapse>
  </b-card>
</template>

<script>

export default {
  name: 'JobPurchaseInvoicesCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    totals: {
      type: Object,
      default: () => ({
        count: 0,
        total_gross: 0,
        total_invoice: 0,
        total_retention: 0,
      }),
    },
    collapseEnabled: {
      type: Boolean,
      default: false,
    },
    bgClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      collapse: !this.collapseEnabled,
    }
  },
  computed: {
    ordersCount() {
      return this.totals.count || 0
    },
    totalGross() {
      return this.totals.total_gross || 0
    },
    totalInvoice() {
      return this.totals.total_invoice || 0
    },
    totalRetention() {
      return this.totals.total_retention || 0
    },
  },
}
</script>

<style scoped>
.name-width {
  width: 205px;
}

.collapse-col-width {
  width: 75px;
}
</style>
