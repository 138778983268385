<template>
  <div>
    <b-card
      no-body
      class="mb-1 bg-action-blue"
    >
      <b-row
        align-h="between"
        align-v="center"
        class="p-2"
      >
        <b-col
          cols="auto"
          class="text-gray-50 font-weight-bold name-width"
        >
          <span class="text-uppercase">totales globales</span>
        </b-col>
        <b-col cols="auto">
          <span class="font-weight-bold">{{ totalItems | numberToLocalString }}</span> Facturas emitidas
        </b-col>
        <b-col class="text-uppercase text-right">
          <div>total bruto</div>
          <div class="font-weight-bold">
            {{ totals.total_gross | numberToLocalString }} €
          </div>
        </b-col>
        <b-col class="text-uppercase text-right">
          <div>total retención</div>
          <div class="font-weight-bold">
            {{ totals.total_retention | numberToLocalString }} €
          </div>
        </b-col>
        <b-col class="text-uppercase text-right">
          <div>total liquido</div>
          <div class="font-weight-bold">
            {{ totals.total_liquid | numberToLocalString }} €
          </div>
        </b-col>
        <b-col class="text-uppercase text-right">
          <div>total</div>
          <div class="font-weight-bold">
            {{ totals.total_invoice | numberToLocalString }} €
          </div>
        </b-col>
        <b-col
          cols="auto"
          class="collapse-col-width"
        />
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-1"
    >
      <b-row
        align-h="between"
        align-v="center"
        class="p-2"
      >
        <b-col
          cols="auto"
          class="text-gray-50 font-weight-bold name-width"
        >
          <span class="text-uppercase">facturas emitidas</span>
        </b-col>
      </b-row>
      <SaleInvoicesTable
        :job-id="jobId"
        :jobContractId="contractId"
        hide-column-actions
        hide-toolbar
        @data-loaded="handleTableDataLoaded"
      />
    </b-card>
  </div>
</template>

<script>
import SaleInvoicesTable from '@/components/sale-invoices/table/SaleInvoicesTable.vue'

export default {
  name: 'JobSaleInvoicesCard',
  components: { SaleInvoicesTable },
  props: {
    jobId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totals: {},
      totalItems: 0,
    }
  },
  computed: {
    contractSelected () {
      return this.$store.getters['job/getContractSelected'](this.jobId)
    },
    contractId () {
      return this.contractSelected?.contract?.id
    }
  },
  mounted() {
    this.$store.commit('saleInvoice/setSaleInvoicesFilters', this.job)
  },
  methods: {
    handleTableDataLoaded({ totals, totalItems }) {
      this.totals = totals
      this.totalItems = totalItems
    },
  },
}
</script>