<template>
  <BaseModal v-model="model" size="md" :loading="loading" @confirm-button-click="updateContract">
    <div style="padding-bottom: 7em; padding-top: 6em">
      <h2 class="text-center">Actualizar contrato</h2>
      <validation-provider name="Fecha de inicio" rules="required">
        <b-form-group label="Fecha de inicio" label-for="date-init" class="m-4">
          <b-form-datepicker
            id="date-init"
            :value="init_contract"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            size="sm"
            :hide-header="true"
            placeholder="Fecha de inicio"
            :label-help="null"
            :max="end_contract"
            @input="init_contract = $event"
            start-weekday="1"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider name="Fecha de finalizacion" rules="required">
        <b-form-group
          label="Fecha de finalizacion"
          label-for="date-end"
          class="m-4"
        >
          <b-form-datepicker
            id="date-end"
            :value="end_contract"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            size="sm"
            :hide-header="true"
            placeholder="Fecha de finalizacion"
            :label-help="null"
            :min="init_contract"
            @input="end_contract = $event"
            start-weekday="1"
          />
        </b-form-group>
      </validation-provider>
      <b-form-group
        class="mx-4"
        label="Referencia cliente"
        label-for="ref-client"
      >
        <b-form-input
          id="ref-client"
          v-model="ref_client"
          placeholder="Referencia cliente"
        />
      </b-form-group>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import { mapGetters } from 'vuex'

export default {
  name: "JobRangeContractModal",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    jobId: {
      type: Number,
    },
  },
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      init_contract: "",
      end_contract: "",
      ref_client: "",
      key: new Date()
    };
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
    }),
    contractSelected() {
      return this.$store.getters["job/getContractSelected"](this.jobId);
    },
    model: {
      get() {
        if (this.value) {
          this.init_contract = this.contractSelected.contract.init_contract;
          this.end_contract = this.contractSelected.contract.end_contract;
          this.ref_client = this.contractSelected.contract.ref_client
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async updateContract() {
      this.loading = true
      
      try {
        let params = {
          init_contract : this.init_contract,
          end_contract: this.end_contract,
          ref_client: this.ref_client,
        }
        await this.$store.dispatch('job/updateContract', 
          { contract_id: this.contractSelected.contract.id, params, job_id: this.jobId }
        )
        window.location.reload()
      }  finally {
        this.loading = false
      }
    }
  }
};
</script>
