<template>
  <b-card no-body>
    <b-row
      align-h="between"
      align-v="center"
      no-gutters
      class="px-2 bg-light-yellow"
    >
      <b-col
        cols="auto"
        class="font-weight-bold text-uppercase py-1"
      >
        {{ title }}
      </b-col>
      <b-col cols="auto">
        <b-row>
          <b-col
            v-for="totalConfiguration in totalsConfiguration"
            :key="totalConfiguration.key"
            cols="auto"
            class="py-1"
          >
            <div class="font-weight-bold text-uppercase text-right">
              {{ totalConfiguration.label }}
            </div>
            <div class="text-primary font-weight-bold text-right">
              {{ totals[totalConfiguration.key] | numberToLocalString }} €
            </div>
          </b-col>
          <b-col class="py-1" cols="auto" v-if="!readonly && showAddButton">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="creationModalVisible = true"
            >
              {{ addButtonText }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <hr class="m-0">
      <transition-group name="list">
        <template
          v-for="jobLine in jobLines"
        >
          <div :key="jobLine.id">
            <slot :lines-data="jobLine" />
          </div>
        </template>
      </transition-group>
    </b-overlay>
    <JobLineCreationModal
      v-model="creationModalVisible"
      :job-id="jobId"
      @create-line="handleCreateLine"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import JobLineCreationModal from '@/components/jobs/modal/JobLineCreationModal.vue'

export default {
  name: 'JobLinesCard',
  components: { JobLineCreationModal },
  props: {
    jobId: {
      type: String,
      default: null,
    },
    jobLines: {
      type: Array,
      default: () => [],
    },
    totals: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    addButtonText: {
      type: String,
      default: '',
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalsConfiguration: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('job', {
      job: 'getJob',
      readonly: 'getIsReadOnlyMode'
    }),
  },
  methods: {
    async handleCreateLine(line) {
      this.creationModalVisible = false
      this.$emit('create-line', line)
    },
  },
}
</script>

<style scoped>

.list-enter-active {
  height: 68px;
  transition: all 0.6s cubic-bezier(.36,-0.64,.34, 1.76);
}

.list-enter {
  height: 0;
}
</style>
