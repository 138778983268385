<template>
  <div>
    <b-card no-body class="p-2 bg-action-blue">
      <b-row align-h="between" align-v="center">
        <b-col cols="auto" class="text-gray-50 font-weight-bold name-width">
          <span class="text-uppercase">totales globales</span>
        </b-col>
        <b-col cols="auto">
          <span class="font-weight-bold">{{ totalItems }}</span> Certificaciones
          generadas
        </b-col>
        <b-col class="text-uppercase text-right">
          <div>facturado</div>
          <div class="font-weight-bold">
            {{ totals.certified | numberToLocalString }} €
          </div>
        </b-col>
        <b-col class="text-uppercase text-right">
          <div>pdte. facturar</div>
          <div class="font-weight-bold">
            {{ totals.pending | numberToLocalString }} €
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-row align-h="between" align-v="center" class="p-2">
        <b-col cols="auto" class="text-gray-50 font-weight-bold name-width">
          <span class="text-uppercase">certificaciones</span>
        </b-col>
        <b-col cols="auto">
          <b-button
            v-if="!readonly"
            variant="primary"
            class="rounded-sm px-2"
            @click="handleNewCertificationButtonClick"
          >
            Generar nueva certificación
          </b-button>
        </b-col>
      </b-row>
      <CertificationsTable
        ref="certifications-table"
        :job-id="jobId"
        :initial-order-by="{ column: 'certification_date', type: 'asc' }"
        hide-toolbar
        :disable-pagination="disablePagination"
        @data-loaded="handleCertificationsTableDataLoaded"
       
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CertificationsApi from "@/api/certifications-api";
import CertificationsTable from "@/components/certifications/table/CertificationsTable.vue";

export default {
  name: "JobCertificationsCard",
  components: { CertificationsTable },
  props: {
    jobId: {
      type: String,
      default: "",
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      totals: {},
      totalItems: 0,
    };
  },
  computed: {
    ...mapGetters("job", {
      job: "getJob",
      readonly: "getIsReadOnlyMode",
    }),
  },
  methods: {
    async handleNewCertificationButtonClick() {
      this.loading = true;
      try {
        await CertificationsApi.create({ job_id: this.jobId });
        await this.$refs["certifications-table"].loadData();
      } catch (error) {
        this.handleSaveCertificationError(error);
      } finally {
        this.loading = false;
      }
    },
    handleSaveCertificationError(error) {
      if (error.response.data.job_id) {
        this.$toast.error(error.response.data.job_id[0]);
        return;
      }

      this.$toast.error(
          "Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde."
      );
    },
    handleCertificationsTableDataLoaded({ totals, totalItems }) {
      this.totals = totals;
      this.totalItems = totalItems;
    },
  },
};
</script>

<style scoped></style>
