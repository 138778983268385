<template>
  <div>
    <BaseTable
      ref="maintenance-certifications-table"
      :loading="loading"
      :totals-configuration="totalsConfiguration"
      :resourceStore="'maintenanceCertifications'"
      :columns="columns"
      :resource="$data.$constants.RESOURCES.RESOURCE_MAINTENANCE_CERTIFICATIONS"
      :hide-toolbar="hideToolbar"
      :disable-pagination="disablePagination"
      :hideUploadButton="true"
      :hidePrinterButton="true"
      :filters="baseFilters"
      @data-loaded="$emit('data-loaded', $event)"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(maintenance_certification_date)="data">
        <span>{{ data.value | formatDate }} </span>
      </template>
      <template #cell(job)="data">
        {{ data.value ? data.value.code : "" }}
      </template>
      <template #cell(status)="data">
        <StatusBadge
          v-if="data.item.status.name === 'Facturada'"
          class="ml-1"
          :status="data.item.status.name"
          :text="data.item.status.name"
        />
        <StatusSelect
          v-else
          v-model="data.item.status"
          class="font-small-3 d-inline-block"
          :clearable="false"
          :type="statusType"
          @selection-change="handleStatusChange(data.item)"
        />
      </template>
      <template #cell(client)="data">
        {{ data.value ? data.value.name : "" }}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-show="data.item.observations"
          :id="`popover-target-${data.item.id}`"
          icon="InfoIcon"
          size="18"
          class="text-indigo mr-1"
        />
        <b-popover :target="`popover-target-${data.item.id}`" triggers="hover" placement="top">
          <template #title>Observaciones</template>
          <pre style="background: #ffff;">{{ data.item.observations }}</pre>
        </b-popover>
        <b-link v-if="isCertificationBilling(data.item)">
          <b-button
            variant="outline-primary"
            class="text-nowrap rounded-sm px-2 bill-button"
            :to="{
              name: 'viewSaleInvoice',
              params: { id: data.item.sale_invoice.id },
            }"
          >
            Ver factura
          </b-button>
        </b-link>
        <b-link v-if="isCertificationPendingBilling(data.item) && canAccessResource($data.$constants.RESOURCES.RESOURCE_SALE_INVOICES, RESOURCE_ACTION_ALL)">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2 bill-button"
            @click="handleInvoiceButtonClick(data.item)"
          >
            Facturar
          </b-button>
        </b-link>
        <b-link
          :to="{
            name: 'viewMaintenanceCertification',
            params: { id: data.item.id },
          }"
          class="d-inline-block px-1 text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Ver"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          class="d-inline-block text-danger pr-1"
          @click="handleCertificationDeleteIconClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </template>
    </BaseTable>
    <MaintenanceCertificationsListFilters
      ref="maintenance-certifications-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('maintenanceCertifications/setFilters', $event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import MaintenanceCertificationsListFilters from "@/components/maintenance-certifications/filters/MaintenanceCertificationsListFilters.vue";
import MaintenanceCertificationsApi from "@/api/maintenance-certifications";
import { MAINTENANCE_CERTIFICATIONS_TYPE } from "@/api/status-api";
import { mapGetters } from "vuex";
import { RESOURCE_ACTION_ALL } from '@/shared/constants/resourceActions'

export default {
  name: "MaintenanceCertificationsTable",
  props: {
    jobId: {
      type: String,
      default: null,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseTable,
    StatusSelect,
    StatusBadge,
    MaintenanceCertificationsListFilters,
  },
  data() {
    return {
      RESOURCE_ACTION_ALL,
      rowsEditionEnabled: [],
      loading: false,
      filtersVisible: false,
      statusType: MAINTENANCE_CERTIFICATIONS_TYPE,
      totalsConfiguration: [
        { colspan: 2 },
        { label: "TOTAL BRUTO", key: "total_gross", unit: "€" },
        { label: "TOTAL LIQUIDO", key: "total_liquid", unit: "€" },
        { label: "TOTAL", key: "total_invoice", unit: "€" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filters: "maintenanceCertifications/getFilters",
      isMaintenance: 'job/getIsMaintenance',
      isService: 'job/getIsService',
      canAccessResource: "auth/canAccessResource"
    }),
    columns() {
      return [
        {
          label: "FECHA",
          key: "maintenance_certification_date",
          sortable: true,
          thStyle: { "min-width": "150px" },
        },
        {
          label: "JOB",
          key: "job",
          sortable: true,
          thStyle: { "min-width": "200px" },
        },
        {
          label: "CLIENTE",
          key: "client",
          sortable: true,
        },
        {
          label: "ESTADO",
          key: "status",
          sortable: true,
          thStyle: { "min-width": "260px" },
        },
        {
          label: "",
          key: "actions",
          thStyle: { "min-width": "260px" },
          tdClass: "text-right",
        },
      ];
    },
    contractSelected () {
      return this.jobId && this.isMaintenance ? this.$store.getters['job/getContractSelected'](this.jobId) : null
    },
    baseFilters() {
      return this.isMaintenance && this.contractSelected ? 
        { ...this.filters, job_contract_id: this.contractSelected.contract.id } :
        { job_id: this.jobId , ...this.filters }
    },
  },
  methods: {
    loadData() {
      return this.$refs["maintenance-certifications-table"].loadData();
    },
    handleEditStatusClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, true);
    },
    isCertificationPendingBilling(certification) {
      return certification.status.name === "Pendiente de facturar";
    },
    isCertificationBilling(certification) {
      return certification.status.name === "Facturada";
    },
    async handleInvoiceButtonClick(certification) {
      this.loading = true;
      try {
        await MaintenanceCertificationsApi.createSaleInvoice(certification.id);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    async handleStatusChange(item) {
      this.loading = true
    
      try {
        const response = await MaintenanceCertificationsApi.edit(item.id, { status: item.status })
        this.status = response.data.status
      } catch (_) {
        this.loadData()
      }
      this.loading = false
    },
    handleEditIconClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, true);
    },
    async handleCertificationDeleteIconClick(certification) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el registro.",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await MaintenanceCertificationsApi.delete(certification.id);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    handleClearListFilters() {
      this.$store.commit("maintenanceCertifications/setFilters", {});
      this.$refs["maintenance-certifications-list-filters"].clearFilters();
    },
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  border: none;
}
</style>
