import { BaseApi } from '@/api/base-api'
import { RESOURCE_JOBS_ADDITIONAL_LINES } from '@/shared/constants/resources'
import Vue from 'vue'

class JobAditionalLinesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }
  deleteDocument (id_job_additional_line, id_document) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.delete(`/${this.resource}/delete_doc/${id_job_additional_line}/${id_document}`)
        resolve(response.data.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar el documento. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
}

export default new JobAditionalLinesApi(RESOURCE_JOBS_ADDITIONAL_LINES)
