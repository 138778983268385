var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"size":"md","loading":_vm.loading},on:{"confirm-button-click":_vm.updateContract},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('div',{staticStyle:{"padding-bottom":"7em","padding-top":"6em"}},[_c('h2',{staticClass:"text-center"},[_vm._v("Actualizar contrato")]),_c('validation-provider',{attrs:{"name":"Fecha de inicio","rules":"required"}},[_c('b-form-group',{staticClass:"m-4",attrs:{"label":"Fecha de inicio","label-for":"date-init"}},[_c('b-form-datepicker',{attrs:{"id":"date-init","value":_vm.init_contract,"date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },"size":"sm","hide-header":true,"placeholder":"Fecha de inicio","label-help":null,"max":_vm.end_contract,"start-weekday":"1"},on:{"input":function($event){_vm.init_contract = $event}}})],1)],1),_c('validation-provider',{attrs:{"name":"Fecha de finalizacion","rules":"required"}},[_c('b-form-group',{staticClass:"m-4",attrs:{"label":"Fecha de finalizacion","label-for":"date-end"}},[_c('b-form-datepicker',{attrs:{"id":"date-end","value":_vm.end_contract,"date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },"size":"sm","hide-header":true,"placeholder":"Fecha de finalizacion","label-help":null,"min":_vm.init_contract,"start-weekday":"1"},on:{"input":function($event){_vm.end_contract = $event}}})],1)],1),_c('b-form-group',{staticClass:"mx-4",attrs:{"label":"Referencia cliente","label-for":"ref-client"}},[_c('b-form-input',{attrs:{"id":"ref-client","placeholder":"Referencia cliente"},model:{value:(_vm.ref_client),callback:function ($$v) {_vm.ref_client=$$v},expression:"ref_client"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }